import type { MantineColorsTuple } from '@mantine/core';
import {
  Autocomplete,
  Button,
  Card,
  Container,
  createTheme,
  Input,
  MultiSelect,
  NativeSelect,
  NumberInput,
  PasswordInput,
  rem,
  Select,
  Table,
  Textarea,
  TextInput,
} from '@mantine/core';
import { DateInput, DatePickerInput, DateTimePicker } from '@mantine/dates';

const brand: MantineColorsTuple = [
  '#e4fcfa',
  '#d7f3ef',
  '#b5e4dd',
  '#8fd5ca',
  '#6ec8bb',
  '#5ac0b0',
  '#4cbcab',
  '#3ba596',
  '#2d9485',
  '#118072',
];

const blue: MantineColorsTuple = [
  '#eaf2ff',
  '#d6e1fb',
  '#abc0ef',
  '#7e9de5',
  '#5880dc',
  '#416dd7',
  '#3264d6',
  '#2454be',
  '#1b4aab',
  '#084098',
];

const green: MantineColorsTuple = [
  '#e7fef1',
  '#d5f8e5',
  '#aceeca',
  '#80e5ad',
  '#5bdd94',
  '#44d884',
  '#35d67c',
  '#26bd69',
  '#1aa85d',
  '#00914d',
];

const CONTAINER_SIZES: Record<string, string> = {
  xs: rem(600),
  sm: rem(1100),
  md: rem(1600),
  lg: rem(2100),
  xl: rem(2600),
};

export const theme = createTheme({
  fontFamily: '"Mabry Pro", sans-serif',
  primaryColor: 'blue',
  colors: {
    brand,
    blue,
    green,
  },
  components: {
    Autocomplete: Autocomplete.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    Badge: Button.extend({
      defaultProps: {
        variant: 'light',
      },
    }),
    Button: Button.extend({
      defaultProps: {
        fw: 500,
        size: 'md',
      },
    }),
    Card: Card.extend({
      defaultProps: {
        radius: 'md',
        shadow: 'md',
      },
    }),
    Container: Container.extend({
      vars: (_, { size, fluid }) => ({
        root: {
          '--container-size':
            fluid != null
              ? '100%'
              : size !== undefined && size in CONTAINER_SIZES
                ? CONTAINER_SIZES[size]
                : rem(size),
        },
      }),
    }),
    DateInput: DateInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    DatePickerInput: DatePickerInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    DateTimePicker: DateTimePicker.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    Input: Input.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    MultiSelect: MultiSelect.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    NativeSelect: NativeSelect.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    NumberInput: NumberInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    PasswordInput: PasswordInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    Select: Select.extend({
      defaultProps: {
        size: 'md',
      },
    }),
    Table: Table.extend({
      defaultProps: {
        horizontalSpacing: 'md',
        verticalSpacing: 'md',
      },
    }),
    Textarea: Textarea.extend({
      defaultProps: {
        rows: 5,
      },
    }),
    TextInput: TextInput.extend({
      defaultProps: {
        size: 'md',
      },
    }),
  },
});
